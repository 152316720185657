
import { AccountController } from "@/app/ui/controllers/AccountController";
import { Vue, Options, prop } from "vue-class-component";
import Card from "./card.vue";

class Props {
  column = prop<number>({
    default: 3,
    type: Number
  });
}
@Options({
  components: {
    Card
  }
})
export default class Dashboard extends Vue.with(Props) {
  get dashboardDeliveryData() {
    return AccountController.dashboardDeliveryData;
  }
}
